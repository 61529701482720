import {HandleCallback,EnsureLogin} from './auth.js';
import {HandleHome} from './home.js';

function router() {
	const path = window.location.pathname.slice(1) || '/';
	switch(path){
		case 'callback':
			HandleCallback();
			break;
		case '/':
			if (EnsureLogin()){
				HandleHome();
			}
			break;
		default:

	}
}

class RouterNode extends HTMLElement {
	constructor(){
		super();
	}

	connectedCallback(){
		SetupRouter();
	}
}

customElements.define('bolt6-auth-router',RouterNode);

export function SetupRouter(){
	window.addEventListener('hashchange', router)
window.history.replaceState = new Proxy(window.history.replaceState, {
  apply: (target, thisArg, argArray) => {
    const resp = target.apply(thisArg, argArray);

	  router();

	  return resp;
  },
});
	window.history.pushState = new Proxy(window.history.pushState, {
  apply: (target, thisArg, argArray) => {
    const resp= target.apply(thisArg, argArray);


	  router();
	  return resp;
  },
});
	window.addEventListener('load', router)
}


class ViewersList extends HTMLElement {
	constructor(){
		super();
	}

	connectedCallback(){
		this.shadow = this.attachShadow({mode: "open"});
		const style = document.createElement('style');
		style.textContent=`
		b6-viewer{
		    width: var(--portrait-size);
    max-width: var(--portrait-size);
    height: var(--portrait-size);
    border: 1.5px solid var(--bar-bg-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-color: orange;
    color: white;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
		}
		`
		this.shadow.appendChild(style);
		this.UsersNum = document.createElement("span");
		this.UsersBox = document.createElement("div");
		this.UsersBox.style.display='flex';

		this.shadow.appendChild(this.UsersNum)
		this.shadow.appendChild(this.UsersBox);

		this.users = {};
		const self = this;

		this.usersAddedCallback=(event)=>{
			if (!event.detail || !event.detail.user) {
				return;
			}

			const user = event.detail.user;
			const uid = user.id;

			if (!(uid in self.users)) {
				const portrait = document.createElement("b6-viewer");

				// Should be uid if there would be a chance to recover user details from uid
				portrait.setAttribute("uid",uid);
				portrait.setAttribute("name",user.name);

				portrait.id="--6b-ulist-id-"+uid;

				self.users[uid]={dom: portrait};
				self.UsersBox.appendChild(portrait);
			}
			self.users[uid].lastuser=event.detail.user;

			self.users[uid].dom.setAttribute('readonly',event.detail.user.viewOnly.toString());
//			self.UsersNum.innerHTML = Object.keys(self.users).length+" users";
		}

		this.sessionClosedCallback=(event)=>{

			Object.keys(self.users).forEach(user=>{
				const dom = user.dom;
				if (dom && dom.parentNode){
					dom.parentNode.removeChild(dom);
				}
			})
			self.users={}
			self.UsersNum.innerHTML="";
			self.UsersBox.innerHTML="";
		}

		this.usersUpdatedCallback=(event)=>{

			const userIds = event.detail.users.map(e=>e.toString());
			Object.keys(self.users).forEach(uid=>{

				if (userIds.includes(uid)==false){
					const dom = self.users[uid].dom;
					dom.parentNode.removeChild(dom);

				delete self.users[uid]
				}

			})
		}

		this.makeCurrentUserViewOnlyCallback=(event)=>{
			window.viewonly=true;
			window.localStorage.setItem("viewonly", window.viewonly.toString());

			document.dispatchEvent(new Event("ViewOnlyChanged"));
		}

		document.addEventListener("b6-make-current-user-view-only", this.makeCurrentUserViewOnlyCallback);
		document.addEventListener("b6-users-updated", this.usersUpdatedCallback);
		document.addEventListener("b6-users-added",this.usersAddedCallback)
		document.addEventListener("b6-session-closed", this.sessionClosedCallback);
	}

	disconnectedCallback(){
		document.removeEventListener("b6-make-current-user-view-only", this.makeCurrentUserViewOnlyCallback);
		document.removeEventListener("b6-users-updated", this.usersUpdatedCallback);
		document.removeEventListener("b6-users-added",this.usersAddedCallback)
		document.removeEventListener("b6-session-closed", this.sessionClosedCallback);
	}
}

customElements.define('b6-viewers-list',ViewersList);

class ListItemApp extends HTMLElement {
	constructor(){
		super();
		console.log("app list item created");
	}
}

customElements.define('b6-li-app',ListItemApp);

class UserPortrait extends HTMLElement {

	static observedAttributes=['name','readonly'];

	constructor(){
		super();
	}

	connectedCallback(){
		const Letters = document.createElement("span");
		Letters.style.padding="30px";
		const shadow = this.attachShadow({ mode: "open" });
		shadow.appendChild(Letters);

		const uid = this.getAttribute("name");
		const readonly = this.getAttribute("readonly")=="true";

		this.Letters = Letters;

		if (uid.length>0){
			this.Letters.innerHTML=uid.substr(0,1);
		}

		if (readonly) {
			this.Letters.style.background=readony?"#333333":"#ff0080";
		}
	}


	attributeChangedCallback(name, oldValue, newValue){
		if (!this.Letters){
			return
		}

		if (oldValue==newValue){
			return;
		}

		console.log(`attribute changed ${name} from ${oldValue} to ${newValue}`);

		if (name=='name') {
			if (newValue.length>0){
				this.Letters.innerHTML=newValue.substr(0,1);
			}else {
				debugger
				this.Letters.innerHTML='';
			}
		}

		if (name=='readonly'){
			this.Letters.style.background=newValue=="true"?"#333333":"#ff0080";
		}
	}
}

customElements.define('b6-viewer',UserPortrait);
